
<template>
  <div class="popup-notice">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            {{i18n.noticeDetail}}
          </span>
        </h3>
<!--        <div class="card-toolbar toolbar-symbol">-->
<!--          <b-button variant="dark" class="btn-icon rounded-lg" size="sm">-->
<!--            <i class="la la-close font-size-h5"></i>-->
<!--          </b-button>-->
<!--        </div>-->
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <div class="notice-view px-lg-6 px-0 mb-4">
          <dl class="text-group row">
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">{{i18n.registrant}}</span>
            </div>
            <div class="description col-12 col-lg-5 mb-3 mb-lg-3">
              <span class="text text-truncate">{{ memberNm }}</span>
            </div>
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">{{i18n.registeredDate}}</span>
            </div>
            <div class="description col-12 col-lg-5 mb-3 mb-lg-3">
              <span class="text text-truncate">{{ createTime }}</span>
            </div>
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">{{i18n.title}}</span>
            </div>
            <div class="description col-12 col-lg-11 mb-3 mb-lg-3">
              <span class="text">{{ title }}</span>
            </div>
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">{{i18n.detail}}</span>
            </div>
            <div class="description col-12 col-lg-11 mb-3 mb-lg-3">
                <editor v-model="content" />
            </div>
            <div v-if="isAttachmentExist()" class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">{{i18n.download}}</span>
            </div>
            <div v-for="(attachment, index) in attachments" :key ="index" class="description col-12 col-lg-1 mb-3 mb-lg-3">
              <span v-if="!!attachment">
                <a :href="attachment.path" v-if="!!attachment.fileName">{{ attachment.fileName }}</a>
              </span>
            </div>            
          </dl>
        </div>
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button variant="secondary" size="lg" @click="goBack"><span class="">{{i18n.back}}</span></b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
</template>

<script>
// import {mapGetters} from 'vuex';
import i18n from '@/core/plugins/vue-i18n.js';
import  Editor from '@/components/text-editor/Tiptap.vue'
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_GET_NOTICE} from '@/core/services/variable';
import {getItem, lengthCheck, syncObj2, timestampToDate} from '@/core/services/funcs';

export default {
  name: 'NoticeView',
  components: {
    Editor
  },
  beforeMount() {
    this.noticeNo = parseInt(this.$route.params.noticeNo || 0)
    this.$store.dispatch(ACT_GET_NOTICE, this.noticeNo).then(resp => {
      if(lengthCheck(resp)){
        syncObj2(this, getItem(resp));
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: '공지사항', icon: 'fas fa-flag' }]);
    window.scrollTo(0,0);
  },
  computed: {
    createTime() {
      if(this.createDt) return timestampToDate(this.createDt);
      return '';
    },
    attachments() {
      return [{
        fileName: this.attachFileNm1,
        path: this.attachFilePath1,
      },
      { 
        fileName: this.attachFileNm2, 
        path: this.attachFilePath2, 
      },
      { 
        fileName: this.attachFileNm3, 
        path: this.attachFilePath3 
      }]
    }
  },
  data() {
    return {
      i18n: {
        noticeDetail: i18n.t('noticeView.noticeDetail'),
        registrant: i18n.t('noticeView.registrant'),
        registeredDate: i18n.t('noticeView.registeredDate'),
        title: i18n.t('noticeView.title'),
        detail: i18n.t('noticeView.detail'),
        back: i18n.t('noticeView.back'),
        download: i18n.t('noticeView.download'),
      },
      memberNm: '',
      createDt: null,
      title: '',
      content: '',
      attachFileNm1: '',
      attachFilePath1: '',
      attachFileNm2: '',
      attachFilePath2: '',
      attachFileNm3: '',
      attachFilePath3: ''      
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    },
    isAttachmentExist() {
      if (this.attachFilePath1 || this.attachFilePath2 || this.attachFilePath3) {
        return true
      }

      return false
    }
  }
};
</script>
